import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useSnackbar } from 'vue3-snackbar';

export default function useEspecialidadForm(service) {
    const store = useStore();
    const snackbar = useSnackbar();

    const state = reactive({
        id: '',
        name: '',
        femenino: '',
        masculino: '',
        status: 1,
    });

    const setState = (c) => {
        state.id = c.id;
        state.name = c.name;
        state.femenino = c.name;
        state.masculino = c.name;
        state.status = c.status;
    };

    /** ***** Validator ***** */
    const rules = computed(() => ({
        name: { required },
        femenino: { required },
        masculino: { required },
    }));

    const v = useVuelidate(rules, state);

    const resetForm = () => {
        v.value.$reset();
        state.id = '';
        state.name = '';
        state.femenino = '';
        state.masculino = '';
        state.status = 1;
    };

    const handleAction = async (action) => {
        if (await v.value.$validate()) {
            let text;
            switch (action) {
                case 'create':
                case 'create_view':
                    await service.createEspecialidad(state);
                    if (action === 'create_view') {
                        store.dispatch('ContentManager/openWindow', {
                            id: `EspecialidadForm_${service.especialidad.value.id}`,
                            component: 'EspecialidadForm_',
                            name: service.especialidad.value.name,
                            params: {
                                title: 'Especialidad',
                                headerTitle: `Ver ${service.especialidad.value.name}`,
                                item: { ...service.especialidad.value },
                            },
                        });
                    }
                    text = 'Especialidad credo con exito';
                    resetForm();
                    break;
                case 'save':
                    await service.updateEspecialidad(state.id, state);
                    text = 'Especialidad actualizado con exito';
                    break;
                default:
                    break;
            }
            snackbar.add({
                type: 'success',
                text,
            });
        } else {
            console.log(v.$errors);
            snackbar.add({
                type: 'error',
                text: 'Ups no podemos enviar la informacion sin algunos datos',
            });
        }
    };

    return {
        v,
        state,
        setState,
        resetForm,
        handleAction,
    };
}
