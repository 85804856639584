import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function useEspecialidades() {
    const especialidad = ref(null);
    const especialidades = ref([]);

    const getEspecialidad = async (id) => {
        const res = await axios.get(`/care/especialidades/${id}`);

        if (res.status === 200) {
            especialidad.value = res.data;
        }

        return especialidad.value;
    };

    const getEspecialidades = async (searchQuery) => {
        const query = searchQuery ? `?searchQuery=${searchQuery}` : '';

        const res = await axios.get(`/care/especialidades${query}`);

        if (res.status === 200) {
            especialidades.value = res.data.items;
        }
    };

    const createEspecialidad = async (data) => {
        const res = await axios.post('/care/especialidades', data);
        if (res.status === 201) {
            // console.log(res.data);
            especialidad.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updateEspecialidad = async (id, data) => {
        const res = await axios.put(`/care/especialidades/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            especialidad.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const removeEspecialidad = async (id) => {
        const res = await axios.delete(`/care/especialidades/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            especialidad.value = undefined;
            if (especialidades.value) {
                especialidades.value = especialidades.value.filter((e) => e.id !== id);
            }
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        especialidad,
        especialidades,
        getEspecialidad,
        getEspecialidades,
        createEspecialidad,
        updateEspecialidad,
        removeEspecialidad,
    };
}
